import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Transition,
} from "@headlessui/react";
import { DateInput, PrimaryButton, Time } from "@normasteaching/norma-ui";
import { ChangeEvent, useState } from "react";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { SvgClose } from "../Svgs/SvgClose.tsx";
import { useCreateRecurringAvailabilitySlot } from "../../Franchisee/FranchiseeAvailability/useCreateRecurringAvailabilitySlot.ts";
import { useMessagePopupController } from "../MessagePopup/useMessagePopupController.ts";
import { ErrorMessagePopup } from "../MessagePopup/ErrorMessagePopup.tsx";
import { useCreateOneHourAvailabilitySlot } from "../../Franchisee/FranchiseeAvailability/useCreateOneHourAvailabilitySlot.ts";
import { TimeInputsRow } from "./TimeInputsRow.tsx";
import { useWithLoading } from "../Loading/useWithLoading.ts";
import dayjs from "dayjs";
import { ApolloError } from "@apollo/client";

enum RepetitionType {
  DontRepeat = "DontRepeat",
  DailyOnThisWeek = "DailyOnThisWeek",
  DailyOnThisMonth = "DailyOnThisMonth",
  WeeklyOnWeekDay = "WeeklyOnWeekDay",
}

type OptionType = {
  id: string;
  name: string;
};

type WeekOfDay = {
  mo: boolean;
  tu: boolean;
  we: boolean;
  th: boolean;
  sa: boolean;
  su: boolean;
  fr: boolean;
};

type Input = {
  startDateTime: string;
  endDateTime: string;
  timezone: string;
  repeatUntil: string;
  interval: number;
  daysOfWeek: WeekOfDay;
};

type Props = {
  open?: boolean;
  setOpen: (open: boolean) => void;
  valueOne?: string;
  valueTwo?: string;
  options?: OptionType[];
  onAddedSlots?: () => Promise<void>;
  initialDate: Date;
};

export function FormAddAvailabilitySlots({
  open,
  setOpen,
  onAddedSlots,
  initialDate,
}: Props) {
  const {
    show: showError,
    showPopup: showErrorPopup,
    title,
    description,
  } = useMessagePopupController(5 * 1000);
  const [startDate, setStartDate] = useState<Date>(initialDate);
  const [selectedOption, setSelectedOption] = useState<RepetitionType>(
    RepetitionType.DontRepeat,
  );
  const [endDate, setEndDate] = useState<Date>(new Date());
  const { withLoading, loading: creatingSlots } = useWithLoading();
  const { createRecurringAvailabilitySlotMutation } =
    useCreateRecurringAvailabilitySlot();

  const slotTimezone = "Europe/Rome";

  const { createOneHourAvailabilitySlots } = useCreateOneHourAvailabilitySlot();

  const [startTime, setStartTime] = useState<Time>(new Time(12, 0, 0));
  const [endTime, setEndTime] = useState<Time>(new Time(13, 0, 0));

  const getDayName = (date: Date) => {
    const weekday = date.toLocaleDateString("en-US", { weekday: "long" });
    const dayOfMonth = date.getDate();
    return { weekday, dayOfMonth };
  };

  function showAndThrowApiError(error: unknown): void {
    if (
      error instanceof ApolloError &&
      error.graphQLErrors.some(
        (ge) => ge.extensions?.code === "ALREADY_EXISTING_RECURRING_SLOT_ERROR",
      )
    ) {
      showErrorPopup(
        "Ops!",
        "The selected recurrence conflicts with an existing time slot. Please choose a different time range or adjust the recurrence settings.",
      );
      throw error;
    }

    showErrorPopup(
      "Ops!",
      `An error occurred while adding the slot: ${(error as Error)?.message ?? "Unknown error"}`,
    );
    throw error;
  }

  async function createSingleAvailabilitySlot(
    startDateTime: Date,
    endDateTime: Date,
    timezone: string,
  ) {
    try {
      await createOneHourAvailabilitySlots({
        input: {
          timezone: timezone,
          startDateTime: startDateTime.toISOString(),
          endDateTime: endDateTime.toISOString(),
        },
      });
      setOpen(false);
    } catch (e) {
      showAndThrowApiError(e);
    }
  }

  async function createRepetition(
    startDateTime: Date,
    endDateTime: Date,
    timezone: string,
    selectedOptionValue: RepetitionType,
  ) {
    const input: Input = {
      startDateTime: startDateTime.toISOString(),
      endDateTime: endDateTime.toISOString(),
      timezone: timezone,
      repeatUntil: "",
      interval: 1,
      daysOfWeek: {
        mo: false,
        tu: false,
        we: false,
        th: false,
        fr: false,
        sa: false,
        su: false,
      },
    };

    if (selectedOptionValue === RepetitionType.DailyOnThisWeek) {
      const firstSaturday = getFirstSaturdayAfter(startDateTime);
      input.repeatUntil = firstSaturday.toISOString();
      input.daysOfWeek = getDaysOfWeekBetween(startDateTime, firstSaturday);
    } else if (selectedOptionValue === RepetitionType.DailyOnThisMonth) {
      const endOfMonth = new Date(
        startDateTime.getFullYear(),
        startDateTime.getMonth() + 1,
        0,
      );
      input.repeatUntil = endOfMonth.toISOString();
      input.daysOfWeek = {
        mo: true,
        tu: true,
        we: true,
        th: true,
        fr: true,
        sa: true,
        su: true,
      };
    } else if (selectedOptionValue === RepetitionType.WeeklyOnWeekDay) {
      if (!endDate) {
        alert("Please select an end date");
        return;
      }
      input.repeatUntil = new Date(endDate).toISOString();

      const dayOfWeek = startDateTime.getDay();
      const daysOfWeekMap: (keyof WeekOfDay)[] = [
        "su",
        "mo",
        "tu",
        "we",
        "th",
        "fr",
        "sa",
      ];
      const dayKey = daysOfWeekMap[dayOfWeek];

      input.daysOfWeek[dayKey] = true;
    }

    try {
      await createRecurringAvailabilitySlotMutation({
        input: input,
      });
      setOpen(false);
    } catch (e) {
      showAndThrowApiError(e);
    }
  }

  function getDateTimeInTimezone(
    date: Date,
    time: Time,
    timezone: string,
  ): Date {
    return dayjs(date)
      .tz(timezone, true)
      .hour(time.hour)
      .minute(time.minute)
      .second(time.second)
      .toDate();
  }

  async function createAvailabilitySlot() {
    const selectedOptionValue = selectedOption;
    const startDateTime = getDateTimeInTimezone(
      startDate,
      startTime,
      slotTimezone,
    );
    const endDateTime = getDateTimeInTimezone(startDate, endTime, slotTimezone);

    if (selectedOptionValue === RepetitionType.DontRepeat) {
      await createSingleAvailabilitySlot(
        startDateTime,
        endDateTime,
        slotTimezone,
      );
    } else {
      await createRepetition(
        startDateTime,
        endDateTime,
        slotTimezone,
        selectedOptionValue,
      );
    }
    onAddedSlots?.();
  }

  const handleDateChange = (selectedDate: Date) => {
    setStartDate(selectedDate);
    if (!endDate) {
      setEndDate(selectedDate);
    }
  };

  const handleEndDateChange = (selectedDate: Date) => {
    setEndDate(selectedDate);
  };

  const handleOptionChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(e.target.value as RepetitionType);
    if (e.target.value !== RepetitionType.WeeklyOnWeekDay) {
      setEndDate(new Date());
    }
  };

  const { weekday, dayOfMonth } = getDayName(startDate);

  const getOrdinalSuffix = (day: number) => {
    if (day >= 11 && day <= 13) return "th";
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const options = [
    { id: RepetitionType.DontRepeat, name: "Don't repeat" },
    { id: RepetitionType.DailyOnThisWeek, name: "Daily on this week" },
    { id: RepetitionType.DailyOnThisMonth, name: "Daily on this month" },
    {
      id: RepetitionType.WeeklyOnWeekDay,
      name: `Weekly on ${weekday} until ${
        endDate
          ? `${endDate.getDate()}${getOrdinalSuffix(
              endDate.getDate(),
            )} ${endDate.toLocaleString("en-US", { month: "long" })}`
          : `${dayOfMonth}${getOrdinalSuffix(
              dayOfMonth,
            )} ${startDate.toLocaleString("en-US", { month: "long" })}`
      }`,
    },
  ];

  function getFirstSaturdayAfter(date: Date): Date {
    const resultDate = new Date(date);
    const day = resultDate.getDay();
    const daysUntilSaturday = (6 - day + 7) % 7;
    resultDate.setDate(resultDate.getDate() + daysUntilSaturday);
    return resultDate;
  }

  function getDaysOfWeekBetween(startDate: Date, endDate: Date): WeekOfDay {
    const daysOfWeek = {
      mo: false,
      tu: false,
      we: false,
      th: false,
      fr: false,
      sa: false,
      su: false,
    };
    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const dayOfWeek = currentDate.getDay();
      const daysOfWeekMap: (keyof WeekOfDay)[] = [
        "su",
        "mo",
        "tu",
        "we",
        "th",
        "fr",
        "sa",
      ];
      const dayKey = daysOfWeekMap[dayOfWeek];
      daysOfWeek[dayKey] = true;
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return daysOfWeek;
  }

  return (
    <>
      <ErrorMessagePopup
        title={title}
        description={description}
        show={showError}
      />
      <Transition
        show={open}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Dialog onClose={() => setOpen(!open)} className={``}>
          <DialogBackdrop
            className="fixed inset-0 bg-gray-500/40 z-40 "
            transition
          >
            <div className="flex items-center justify-center min-h-screen">
              <DialogPanel
                transition
                className="relative gap-3 justify-center items-center py-6 z-30 px-10 w-[80%] md:w-[35%] md:h-[40%] h-[45%] shadow-lg bg-white flex flex-col rounded-2xl border-t-8 border-primary"
              >
                <div className="w-full relative flex flex-col items-center">
                  <SvgClose
                    onClick={() => setOpen(!open)}
                    className="absolute w-5 md:w-7 z-20 h-5 md:h-7 mr-0 text-black -right-5 -top-1 md:-top-3 cursor-pointer hover:rotate-180 transition-all ease-in-out"
                  />
                  <h2 className="font-bold tracking-wider">
                    ADD AVAILABILITIES
                  </h2>
                  <div className="w-full flex flex-col gap-1 justify-center">
                    <div className="text-center mb-4 grid grid-cols-1 gap-2 mt-4 text-sm font-semibold">
                      <div className="flex justify-center gap-2 items-center">
                        <p>Select a date</p>
                        <CalendarDaysIcon className="w-4 h-4" />
                      </div>
                      <DateInput
                        canNavigateBack={(currentInterval) => {
                          return currentInterval.fromDate.isAfter(dayjs());
                        }}
                        isDisabledDate={(date) => {
                          return dayjs(date).isBefore(dayjs(), "date");
                        }}
                        value={startDate}
                        onChange={handleDateChange}
                      />
                    </div>
                    <TimeInputsRow
                      startTime={startTime}
                      setStartTime={setStartTime}
                      endTime={endTime}
                      setEndTime={setEndTime}
                      slotTimezone={slotTimezone}
                      date={startDate}
                    />
                    <div className="mt-4 grid grid-cols-1">
                      <select
                        id="repeatOptions"
                        className="mt-2 p-2 border rounded"
                        value={selectedOption}
                        onChange={handleOptionChange}
                      >
                        {options.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    {selectedOption === RepetitionType.WeeklyOnWeekDay && (
                      <div className="mt-4 font-semibold">
                        <p className="text-sm pl-2 mb-2 text-center">
                          Repeat until selected day
                        </p>
                        <DateInput
                          value={endDate}
                          onChange={handleEndDateChange}
                        />
                      </div>
                    )}
                  </div>
                  <div className="mt-4">
                    <PrimaryButton
                      label={"Add Availability"}
                      loading={creatingSlots}
                      onClick={async () =>
                        await withLoading(createAvailabilitySlot)
                      }
                    />
                  </div>
                </div>
              </DialogPanel>
            </div>
          </DialogBackdrop>
        </Dialog>
      </Transition>
    </>
  );
}
